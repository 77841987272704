import React from "react"
import Helmet from "react-helmet"


class HtmlHead extends React.Component {
	render() {
		const domain = 'https://carmenwu.com/'
		const siteName = 'Carmen Wu - Graphic Designer'
		const twitterSite = '@moshicar'

		return (
			<Helmet>
			 	<html lang="en" />
			  <title>{`${this.props.title} | ${siteName}`}</title>
			  <meta name="description" content={this.props.desc} />
			  <link rel="canonical" href={`${domain}${this.props.canonicalURL}`} />

				{/* Facebook */}
			  <meta property="og:title"
			  	content={`${this.props.title} | ${siteName}`}
			  />
			  <meta property="og:type" content="website" />
			  <meta property="og:description" content={this.props.desc} />
			  <meta property="og:url" 
			  	content={`${domain}${this.props.canonicalURL}`} 
			  />
			  <meta property="og:site_name" content={siteName} />
			  <meta property="og:image"
			  	content={`${domain}${this.props.socialImage}`}
			  />

				{/* Twitter */}
			  <meta name="twitter:site" content={twitterSite} />
			  <meta name="twitter:creator" content={twitterSite} />
			  <meta name="twitter:card" content="summary_large_image" />
			  <meta name="twitter:url"
			  	content={`${domain}${this.props.canonicalURL}`}
			  />
			  <meta name="twitter:title"
			  	content={`${this.props.title} | ${siteName}`}
			  />
			  <meta name="twitter:description" content={this.props.desc} />
			  <meta name="twitter:image"
			  	content={`${domain}${this.props.socialImage}`}
			  />


				{/* Fonts */}
				<link href="https://fonts.googleapis.com/css?family=Work+Sans:300,400,700" rel="stylesheet" />

			  <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.8.1/css/all.css" integrity="sha384-50oBUHEmvpQ+1lW4y57PTFmhCaXp0ML5d60M1M7uH2+nqUivzIebhndOJK28anvf" crossorigin="anonymous" />
			</Helmet>
		)
	}
}

export default HtmlHead