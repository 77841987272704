/* Returns array based on array-like object input */
export const toArray = arrLikeObj => Array.prototype.slice.call(arrLikeObj);

/* Searches an elenment's classList for a class match */
export const isTarget = (element, classToMatch) => !(
	toArray(element.classList).indexOf(classToMatch) === -1
);

/* Toggles elements to be tabble or not */
export const setTabbable = (element, flag) =>  element.setAttribute(
	'tabindex', flag ? 0 : -1
);

/* Loops through the GraphQL response and returns requested project data */
export const getProjectObject = (data, projectId) => {
	let proj = {};

  data.forEach(project => {
    if (project.id === projectId) {
      proj = project;
    }
  });

  return proj;
}
