import React from "react"

import ProjectMap from "../components/ProjectMap";
import SocialList from "../components/SocialList";


class SiteFooter extends React.Component {
	render() {
		return (
			<footer role="contentinfo" className="site-footer">
			    <ProjectMap brand={null || this.props.brand} />

			    <SocialList />

			    <p className="copyright">&copy; 2010 &mdash; 2019 Carmen Wu</p>
			</footer>
		)
	}
}

export default SiteFooter