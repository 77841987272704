import React from "react"
import Layout from "../layouts/layout"


export default (children) => (
	<Layout>
		<>
			<h1 className="sr-only">404 | Page Not Found</h1>

			<article>
				<p>This is not the page you are looking for.</p>
				<p>Try again from the <a href="/" target="_top">home page.</a></p>
			</article>
		</>
	</Layout>
)