import React from "react"
import { Link } from "gatsby"

import Navigation from "../components/Navigation"


class SiteHeader extends React.Component {
	render() {
		return (
			<header role="banner" className="site-header">
		      <Link to="/" target="_top">
	          <img className="site-logo" src="/src/images/carmen-wu-logo.svg" alt="Carmen Wu's logo" />
		      </Link>

		      <div className="site-title">Carmen Wu</div>
		      <p className="site-tagline"><span className="lowercase">Graphic</span> designer based in Toronto</p>

		      <Navigation activeNav={this.props.activeNav} />
		  </header>
		)
	}
}

export default SiteHeader