import React from "react";

import HtmlHead from "../components/HtmlHead"
import SiteHeader from "../components/SiteHeader";
import SiteFooter from "../components/SiteFooter";

import { onNavOverlayClick } from "../scripts/navigation"


export default ({ children }) => (
    <>
        <div className="layout-container layout-container--default">
            <HtmlHead
                title="Home"
                desc="Carmen Wu - Toronto-based graphic designer specialized in web & print design. Branding, multi-page editorial, marketing & communications materials."
                canonicalURL="" // home
                socialImage="src/images/projects/doodles/Doodles-hero.jpg"
            />

            <SiteHeader activeNav={"home"} />

            <section className="page-content" role="main">
                { children }
            </section>

            <SiteFooter />
        </div>
        
        <div className="nav-overlay" onClick={onNavOverlayClick} />
    </>
)
