import React from "react";

export default () => (
  <ul className="socialList">
  	<li className="email">
  		<a href="mailto:design@carmenwu.com">
  			<i className="fas fa-envelope"></i><span className="sr-only">design@carmenwu.com</span>
  		</a>
  	</li>

    <li className="instagram">
    	<a href="https://www.instagram.com/moshicar/">
    		<i className="fab fa-instagram"></i><span className="sr-only">Instagram</span>
    	</a>
    </li>

    <li className="twitter">
    	<a href="https://twitter.com/moshicar">
    		<i className="fab fa-twitter"></i><span className="sr-only">Twitter</span>
    	</a>
    </li>

    <li className="linkedin">
    	<a href="https://www.linkedin.com/in/carmenwu73/">
    		<i className="fab fa-linkedin-in"></i><span className="sr-only">LinkedIn</span>
    	</a>
    </li>
  </ul>
);
