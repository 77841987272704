import { toArray, isTarget } from "./helpers"

export const menuClickEvent = event => {
	if (isTarget(event.currentTarget, 'site-navigation__toggle')) {
		const menuClassList = event.currentTarget.parentElement.classList;
    const addOrRemove = toArray(menuClassList).indexOf('open') === -1 ? 
													'add' : 'remove';

    menuClassList[addOrRemove]('open');
    document.querySelector('body').classList[addOrRemove]('nav-active');
	}
}

export const onNavOverlayClick = event => {
  // If `.nav-overlay` clicked, close any modals and hide overlay
	if (isTarget(event.currentTarget, 'nav-overlay')) {
    document.querySelector('.site-navigation').classList.remove('open');
    document.querySelector('body').classList.remove('nav-active');
	}
}