import React from "react"
import * as nav from "../scripts/navigation"


class Navigation extends React.Component {
	render() {
		const activeEls = {};
		activeEls.home = this.props.activeHome || this.props.activeNav === "home";
		activeEls.about = this.props.activeAbout || 
											this.props.activeNav === "about";

		return (
			<nav className="site-navigation">
				<button className="site-navigation__toggle" onClick={nav.menuClickEvent}>
					<span className="middle"></span>
					<span className="sr-only">Toggle Menu</span>
				</button>

				<ul className="site-navigation__menu">
					<li className={ activeEls.home ? "active" : "" }>
						<a href="/" target="_top">
							Work
						</a>
					</li>

					<li className={ activeEls.about ? "active" : "" }>
						<a href="/about" target="_top">
							About + Contact
						</a>
					</li>
				</ul>
			</nav>
		)
	}
}

export default Navigation;