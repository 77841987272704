import React from "react"
import { StaticQuery, graphql, Link } from "gatsby"


class ProjectMap extends React.Component {
	render() {
		const setActive = (brand) => this.props.brand === brand ? 'active' : ''

		return (
		  <StaticQuery
				query={graphql`
				  query ProjectsQuery {
						indexJson {
							projects {
								id,
								projectURL,
								projectBrand
							}
					  }
					}
				`}

				render={data => (
					<nav className="projectMap">
						<ul>
							{data.indexJson.projects.map((project, index) => (
								<li key={index} 
									data-project-id={project.id}
									className="projectMap__item">

									<Link
										to={ "/projects/" + project.projectURL }
										className={setActive(project.projectBrand)}
									>
										{project.projectBrand}
									</Link>
									
								</li>
							))}
						</ul>
					</nav>
				)}
		  />
		)
	}
}

export default ProjectMap